<template>
  <div>
    <h2>Work Permit Converter</h2>
    <h5>Upload a Word Document</h5>
    <input style="margin-left: 5rem;" type="file" @change="handleFileChange">
    <form @submit.prevent="handleFileUpload">
      <button type="submit">Generate PDF Document</button>
    </form>
  </div>
</template>

<script>
import JSZip from "jszip"
import ConvertApi from 'convertapi-js'
import Swal from 'sweetalert2'

var convertAPIKEY = process.env.VUE_APP_CONVERTAPI
// let backend_url = "http://localhost:3000"
let backend_url = "https://srdworkpermit.rampslogistics.com"

export default {
  data() {
    return {
      fields: {},
      selectedFile: null,
      template: "@assets/template.docx"
    };
  },
  methods: {
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
   
    async handleFileUpload() {

      this.showLoading()
      
      let convertApi = ConvertApi.auth(convertAPIKEY)
      let params = convertApi.createParams()
      params.add('File', (this.selectedFile))
      const result = await convertApi.convert('docx', 'pdf', params)

      try {
        const response = await fetch(`${backend_url}/analyze`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ workpermit: result.dto.Files[0].Url }),
        });

        this.fields = await response.json().then(this.generateDocument())
        

      } catch (error) {
        console.error('Error analyzing Document:', error);
      }
    },

    showFailed() {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Loading failed, please try again.',
        allowOutsideClick: false,
      });
      setTimeout(() => {
        Swal.close();
      }, 3000)
    },

    showLoading() {
      Swal.fire({
        title: 'Loading...',
        text: 'Please wait',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })
    },


    async generateDocument() {

      const response = await fetch('/template.docx')
      const arrayBuffer = await response.arrayBuffer()

      const reader = new FileReader()
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result
        const zip = await JSZip.loadAsync(arrayBuffer)
        const docxFile = await zip.file("word/document.xml").async("string")

        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(docxFile, "application/xml")

        // Standard replacements from data object
        for (const [key, value] of Object.entries(this.fields)) {
          this.replaceTextInXML(xmlDoc, key, value);
        }

        // Specific replacements
        const replacements = {
          'Married': 'Gehuwd',
          'Single': 'Ongehuwd',
          'Male': 'mnl.',
          'Female': 'vrl.',
          'Yes': 'Ja',
          'No': 'Nee'
        };

        for (const [key, value] of Object.entries(replacements)) {
          this.replaceTextInXML(xmlDoc, key, value);
        }

        let serializer = new XMLSerializer();
        let updatedDocxFile = serializer.serializeToString(xmlDoc);

        zip.file("word/document.xml", updatedDocxFile);

        const blob = await zip.generateAsync({ type: "blob" });

        let convertApi = ConvertApi.auth(convertAPIKEY)
        let params = convertApi.createParams()
        // params.add('File', (blob))
        params.add('File', new File([blob], 'updated_document.docx'));
        const result = await convertApi.convert('docx', 'pdf', params)

        this.downloadFile(result.dto.Files[0].Url)
        Swal.close()

        // saveAs(blob, "Updated_Document.docx");
      };
      reader.readAsArrayBuffer(new Blob([arrayBuffer]))
    },

    async downloadFile(url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = "Work Permit";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    },



        replaceTextInXML(xmlDoc, searchText, replaceText) {
      const namespaceResolver = (prefix) => {
        const ns = {
          'w': 'http://schemas.openxmlformats.org/wordprocessingml/2006/main'
        };
        return ns[prefix] || null;
      };

      const xpath = `//w:t[contains(text(), '${searchText}')]`;
      const nodes = xmlDoc.evaluate(xpath, xmlDoc, namespaceResolver, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);

      for (let i = 0; i < nodes.snapshotLength; i++) {
        const node = nodes.snapshotItem(i);
        node.textContent = node.textContent.replace(new RegExp(searchText, 'g'), replaceText);
      }
    }
  }
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

div {
  margin-bottom: 10px;
}

label {
  margin-right: 10px;
}

input {
  padding: 5px;
  font-size: 14px;
}

button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

htmlContent {
  width: 210mm; /* A4 width */
  min-height: 297mm; /* A4 height */
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>
